<template>
  <div class="container"><br>  
    <div class="col-12 text-center g">
        <h4>
            <img :src="href + '/files/weeklyplan.png'" alt="" style="width: 40px">
            خطة التعلم الاسبوعية
            <br>
            <small>{{ arr.schoolname }}</small>
        </h4>
    </div>
    <div class="card alert alert-danger g text-center" style="border: 2px solid #888; border-radius: 20px" v-if="noshow">
        {{ noshow }}
    </div>
    <div class="card" style="border: 2px solid #888; border-radius: 20px" v-if="!noshow">
        <div class="card-header text-center">
                <span id="fromto">🗓️ {{ arr.week }}</span> 👤 {{ arr.name }}
        </div>
        <div class="card-footer text-center">
            <button class="btn btn-primary" style="border-radius: 0px" @click="save()" v-if="!saving">
                <i class="fa fa-save"></i>
                حفظ التعديلات
            </button>
            <button class="btn btn-primary disabled" style="border-radius: 0px" disabled v-if="saving">
                <i class="fa fa-save"></i>
                جاري الحفظ....
            </button>
        </div>
        <div class="card-body" style="margin-top: -20px" v-if="done">
            <app-collapse>
                <app-collapse-item v-for="(data, classname, i) in arr.classrooms" :key="classname" class="itemm" :title="'🗓️ ' + classname" :id="`classname_${i}`">
                    <div class="col-12 table-responsive">
                        <table class="table table-sm table-bordered table-striped table-hover bg-white">
                            <thead>
                                <th>
                                    المادة
                                </th>
                                <th>
                                    
                                </th>
                                <th>
                                    الأحد
                                    <br>
                                    <span>{{ arr.dates[0] }}</span>
                                </th>
                                <th>
                                    الاثنين
                                    <br>
                                    <span>{{ arr.dates[1] }}</span>
                                </th>
                                <th>
                                    الثلاثاء
                                    <br>
                                    <span>{{ arr.dates[2] }}</span>
                                </th>
                                <th>
                                    الاربعاء
                                    <br>
                                    <span>{{ arr.dates[3] }}</span>
                                </th>
                                <th>
                                    الخميس 
                                    <br>
                                    <span>{{ arr.dates[4] }}</span>
                                </th>
                            </thead>
                            <tbody>
                                <template v-for="(subject) in subjectsNames(data, classname)">
                                    <tr :key="subject">
                                        <td rowspan="3" style="padding: 0px" class="text-center">
                                            <input type="text" style="padding: 10px; border: 1px solid #ddd;" v-model="titles[subject]" placeholder="اسم المادة هنا...">
                                        </td>
                                        <td>الدرس</td>
                                        <td v-if="checkT(classname, arr.dates[0], subject)">
                                            <input type="text" v-model="data.days[arr.dates[0]][subject].Title" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب...">
                                        </td>
                                        <td v-if="checkT(classname, arr.dates[1], subject)">
                                            <input type="text" v-model="data.days[arr.dates[1]][subject].Title" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب...">
                                        </td>
                                        <td v-if="checkT(classname, arr.dates[2], subject)">
                                            <input type="text" v-model="data.days[arr.dates[2]][subject].Title" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب...">
                                        </td>
                                        <td v-if="checkT(classname, arr.dates[3], subject)">
                                            <input type="text" v-model="data.days[arr.dates[3]][subject].Title" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب...">
                                        </td>
                                        <td v-if="checkT(classname, arr.dates[4], subject)">
                                            <input type="text" v-model="data.days[arr.dates[4]][subject].Title" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب...">
                                        </td>
                                    </tr>
                                    <tr :key="subject + '1'">
                                        <td style="text-align: center">الاهداف</td>
                                        <td>
                                            <textarea rows="3" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب..." v-model="data.days[arr.dates[0]][subject].goal"></textarea>
                                        </td>
                                        <td>
                                            <textarea rows="3" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب..." v-model="data.days[arr.dates[1]][subject].goal"></textarea>
                                        </td>
                                        <td>
                                            <textarea rows="3" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب..." v-model="data.days[arr.dates[2]][subject].goal"></textarea>
                                        </td>
                                        <td>
                                            <textarea rows="3" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب..." v-model="data.days[arr.dates[3]][subject].goal"></textarea>
                                        </td>
                                        <td>
                                            <textarea rows="3" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب..." v-model="data.days[arr.dates[4]][subject].goal"></textarea>
                                        </td>
                                    </tr>
                                    <tr :key="subject + '2'">
                                        <td style="text-align: center">الواجبات</td>
                                        <td>
                                            <textarea rows="3" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب..." v-model="data.days[arr.dates[0]][subject].homeworks"></textarea>
                                        </td>
                                        <td>
                                            <textarea rows="3" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب..." v-model="data.days[arr.dates[1]][subject].homeworks"></textarea>
                                        </td>
                                        <td>
                                            <textarea rows="3" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب..." v-model="data.days[arr.dates[2]][subject].homeworks"></textarea>
                                        </td>
                                        <td>
                                            <textarea rows="3" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب..." v-model="data.days[arr.dates[3]][subject].homeworks"></textarea>
                                        </td>
                                        <td>
                                            <textarea rows="3" class="form-control" style="border-radius: 0px; min-width: 100px" placeholder="اكتب..." v-model="data.days[arr.dates[4]][subject].homeworks"></textarea>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </app-collapse-item>
            </app-collapse>
        </div>
        <textarea class='get-local-storage' name="teachers" style='display: none'></textarea>
    </div><br><br>
  </div>
</template>

<script>
import { VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    AppCollapse,
    AppCollapseItem,
    BDropdownItem,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            err: false,
            arr: {},
            titles: {},
            done: false,
            saving: false,
            href: window.location.href.split('/teacher-weeklyplan/')[0],
            school_id: window.location.href.split("weeklyplan/")[1].split("/")[0],
            teacher_id: window.location.href.split("weeklyplan/")[1].split("/")[1],
            noshow: false,
            days: ["الاحد",'الاثنين','الثلاثاء','الاربعاء','الخميس']
        }
    },
    created(){
        var g = this;
        $.post(api + "/api/reports/teacher/weeklyplan/get", {
            school_id: g.school_id,
            teacher_id: g.teacher_id
        }).then(function(r){
            if(r.status != 100){
                g.noshow = r.response;
                return;
            }
            g.done = true;
            g.arr = r.response;
        }).fail(function(){
                g.noshow = "حدث خطأ في الاتصال";
                return;})
    },
    methods: {
        save(){
            this.saving = true;
            var g = this;
                    var names = {}, i=0;
            for (const [a, b] of Object.entries(g.arr.classrooms)) {
                for (const [c, d] of Object.entries(b)) {
                    for (const [e, f] of Object.entries(d)) {
                        for (const [y, h] of Object.entries(f)) {
                            var o = y;
                            var d2 = y;
                            if(!names[d2]){
                                i++
                                names[d2] = 'المادة هنا #'+ i
                            }
                            var dx = g.titles[d2] ?? names[d2];
                            if(g.titles[d2]){
                                var fx = 0;
                                for (const [key, value] of Object.entries(g.titles)) {
                                    if(value == g.titles[d2]){
                                        fx++;
                                    }
                                }
                                if(fx > 1){
                                    g.titles[d2] = g.titles[d2] + "."
                                    var dx = g.titles[d2] ?? names[d2];
                                }
                            }
                            g.arr.classrooms[a][c][e][`${g.titles[y]}`] = h;
                            if(o != g.titles[y]){
                                delete g.arr.classrooms[a][c][e][o]
                            }
                        }
                    }
                }
            }
            $.post(api + "/api/reports/teacher/weeklyplan/save", {
                school_id: g.school_id,
                teacher_id: g.teacher_id,
                data: JSON.stringify(g.arr)
            }).then(function(r){
                if(r.status == 100){
                    location.reload();
                }else{
                    alert(r.response)
                }
                g.saving = false;
            }).fail(function(){alert("حدث خطأ"); g.saving = false})
        },
        subjectsNames(r, classname){
            var arr = [], g = this;
            for (const [key, valu] of Object.entries(r)) {
                for (const [key, valuex] of Object.entries(valu)) {
                
                for (const [key2, valuex2] of Object.entries(valuex)) {
                    if(!g.titles[key2]){
                        g.titles[key2] = key2
                    }
                    if(!arr.includes(key2)){
                        arr.push(key2?.trim())
                    }
                }
            }
            }
            return arr;
        },
        checkT(a, b, c){
            var g = this;
            if(!g.arr.classrooms[a].days[b]){
                g.arr.classrooms[a].days[b] = {}
            }
            if(!g.arr.classrooms[a].days[b][c]){
                g.arr.classrooms[a].days[b][c] = {Title: "", goal: "", homeworks: ""}
            }
            return true
        }
    }
}
</script>

<style>
#date2{
    display: none;
}
th,td{
    text-align: center;
}
[aria-expanded]:hover{
    background: #eee !important;
}
body{
    background: #fafafa !important;
}
@media only screen and (max-device-width: 640px) {
 
    td { white-space: nowrap !important; }
}
</style>